@import "tailwindcss";

@tailwind base;
@tailwind components;
@tailwind utilities;

@theme {
  --font-noto: 'Noto Sans', sans-serif;
  --font-space: 'Space Grotesk', sans-serif;
}

@layer components {
  /* Primary Button */
  .btn-primary {
    @apply bg-blue-600 text-white px-6 py-3 rounded-xl font-bold
    hover:bg-blue-700 transition-all hover:shadow-lg
    transform hover:-translate-y-0.5
    disabled:opacity-50 disabled:cursor-not-allowed;
  }

  /* Secondary Button */
  .btn-secondary {
    @apply bg-gray-100 text-gray-800 px-6 py-3 rounded-xl font-bold
    hover:bg-gray-200 transition-all hover:shadow-md
    transform hover:-translate-y-0.5
    disabled:opacity-50 disabled:cursor-not-allowed;
  }

  /* Outline Button */
  .btn-outline {
    @apply border-2 border-blue-600 text-blue-600 px-6 py-3 rounded-xl font-bold
    hover:bg-blue-50 transition-all
    transform hover:-translate-y-0.5
    disabled:opacity-50 disabled:cursor-not-allowed;
  }

  /* Small variant */
  .btn-sm {
    @apply px-4 py-2 text-sm;
  }

  /* Large variant */
  .btn-lg {
    @apply px-8 py-4 text-lg;
  }
}
